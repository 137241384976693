import { IUserPermission } from '../models/IUserPermission';

export class PermissionService {
  getAdminPermissions(): IUserPermission[] {
    return [
      {
        permissionType: 'menu',
        id: 'catalogs',
        allowed: true,
      },
      {
        permissionType: 'menu',
        id: 'home',
        allowed: true,
      },
      {
        permissionType: 'menu',
        id: 'timesheet',
        allowed: true,
      },
      {
        permissionType: 'menu',
        id: 'biReport',
        allowed: true,
      },
      {
        permissionType: 'menu',
        id: 'users',
        allowed: true,
      },
      {
        permissionType: 'menu',
        id: 'administrationTime',
        allowed: true,
      },
      {
        permissionType: 'menu',
        id: 'departments',
        allowed: true,
      },
      {
        permissionType: 'menu',
        id: 'customers',
        allowed: true,
      },
      {
        permissionType: 'menu',
        id: 'partners',
        allowed: true,
      },
      {
        permissionType: 'menu',
        id: 'employees',
        allowed: true,
      },
      {
        permissionType: 'menu',
        id: 'projects',
        allowed: true,
      },
      {
        permissionType: 'menu',
        id: 'issues',
        allowed: true,
      },
      {
        permissionType: 'menu',
        id: 'projectDrafts',
        allowed: true,
      },
      {
        permissionType: 'menu',
        id: 'positions',
        allowed: true,
      },
      {
        permissionType: 'menu',
        id: 'workTypes',
        allowed: true,
      },
      {
        permissionType: 'menu',
        id: 'projectTypes',
        allowed: true,
      },
      {
        permissionType: 'menu',
        id: 'reports',
        allowed: true,
      },
      {
        permissionType: 'menu',
        id: 'timesheetReport',
        allowed: true,
      },
      {
        permissionType: 'menu',
        id: 'timesheetEmployeeReport',
        allowed: true,
      },
      {
        permissionType: 'menu',
        id: 'tools',
        allowed: true,
      },
      {
        permissionType: 'menu',
        id: 'updateNotificationDate',
        allowed: true,
      },
      {
        permissionType: 'menu',
        id: 'updateIssueSubject',
        allowed: true,
      },
      {
        permissionType: 'menu',
        id: 'ganttReport',
        allowed: true,
      },
      {
        permissionType: 'menu',
        id: 'ganttReportEmployee',
        allowed: true,
      },
      {
        permissionType: 'menu',
        id: 'workEntryReport',
        allowed: true,
      },
      {
        permissionType: 'menu',
        id: 'timesheetGanttChart',
        allowed: true,
      },
      {
        permissionType: 'menu',
        id: 'documentation',
        allowed: true,
      },
    ];
  }

  getManagerPermissions(): IUserPermission[] {
    return [
      {
        permissionType: 'menu',
        id: 'catalogs',
        allowed: true,
      },
      {
        permissionType: 'menu',
        id: 'biReport',
        allowed: true,
      },
      {
        permissionType: 'menu',
        id: 'home',
        allowed: true,
      },
      {
        permissionType: 'menu',
        id: 'timesheet',
        allowed: true,
      },
      {
        permissionType: 'menu',
        id: 'customers',
        allowed: true,
      },
      {
        permissionType: 'menu',
        id: 'departments',
        allowed: true,
      },
      {
        permissionType: 'menu',
        id: 'partners',
        allowed: true,
      },
      {
        permissionType: 'menu',
        id: 'employees',
        allowed: true,
      },
      {
        permissionType: 'menu',
        id: 'projects',
        allowed: true,
      },
      {
        permissionType: 'menu',
        id: 'positions',
        allowed: true,
      },
      {
        permissionType: 'menu',
        id: 'issues',
        allowed: true,
      },
      {
        permissionType: 'menu',
        id: 'projectDrafts',
        allowed: true,
      },
      {
        permissionType: 'menu',
        id: 'projectTypes',
        allowed: true,
      },
      {
        permissionType: 'menu',
        id: 'reports',
        allowed: true,
      },
      {
        permissionType: 'menu',
        id: 'timesheetReport',
        allowed: true,
      },
      {
        permissionType: 'menu',
        id: 'administrationTime',
        allowed: true,
      },
      {
        permissionType: 'menu',
        id: 'workTypes',
        allowed: true,
      },
      {
        permissionType: 'menu',
        id: 'documentation',
        allowed: true,
      },
    ];
  }

  getHeadOfDepartmentPermissions(): IUserPermission[] {
    return [
      {
        permissionType: 'menu',
        id: 'catalogs',
        allowed: true,
      },
      {
        permissionType: 'menu',
        id: 'home',
        allowed: true,
      },
      {
        permissionType: 'menu',
        id: 'biReport',
        allowed: true,
      },
      {
        permissionType: 'menu',
        id: 'timesheet',
        allowed: true,
      },
      {
        permissionType: 'menu',
        id: 'departments',
        allowed: true,
      },
      {
        permissionType: 'menu',
        id: 'customers',
        allowed: true,
      },
      {
        permissionType: 'menu',
        id: 'partners',
        allowed: true,
      },
      {
        permissionType: 'menu',
        id: 'employees',
        allowed: true,
      },
      {
        permissionType: 'menu',
        id: 'positions',
        allowed: true,
      },
      {
        permissionType: 'menu',
        id: 'projects',
        allowed: true,
      },
      {
        permissionType: 'menu',
        id: 'issues',
        allowed: true,
      },
      {
        permissionType: 'menu',
        id: 'projectDrafts',
        allowed: true,
      },
      {
        permissionType: 'menu',
        id: 'projectTypes',
        allowed: true,
      },
      {
        permissionType: 'menu',
        id: 'reports',
        allowed: true,
      },
      {
        permissionType: 'menu',
        id: 'timesheetReport',
        allowed: true,
      },
      {
        permissionType: 'menu',
        id: 'administrationTime',
        allowed: true,
      },
      {
        permissionType: 'menu',
        id: 'workTypes',
        allowed: true,
      },
      {
        permissionType: 'menu',
        id: 'documentation',
        allowed: true,
      },
    ];
  }

  getHRPermissions(): IUserPermission[] {
    return [
      {
        permissionType: 'menu',
        id: 'catalogs',
        allowed: true,
      },
      {
        permissionType: 'menu',
        id: 'home',
        allowed: true,
      },
      {
        permissionType: 'menu',
        id: 'timesheet',
        allowed: true,
      },
      {
        permissionType: 'menu',
        id: 'administrationTime',
        allowed: true,
      },
      {
        permissionType: 'menu',
        id: 'departments',
        allowed: true,
      },
      {
        permissionType: 'menu',
        id: 'customers',
        allowed: true,
      },
      {
        permissionType: 'menu',
        id: 'partners',
        allowed: true,
      },
      {
        permissionType: 'menu',
        id: 'employees',
        allowed: true,
      },
      {
        permissionType: 'menu',
        id: 'projects',
        allowed: true,
      },
      {
        permissionType: 'menu',
        id: 'issues',
        allowed: true,
      },
      {
        permissionType: 'menu',
        id: 'projectDrafts',
        allowed: true,
      },
      {
        permissionType: 'menu',
        id: 'positions',
        allowed: true,
      },
      {
        permissionType: 'menu',
        id: 'workTypes',
        allowed: true,
      },
      {
        permissionType: 'menu',
        id: 'projectTypes',
        allowed: true,
      },
      {
        permissionType: 'menu',
        id: 'users',
        allowed: true,
      },
      {
        permissionType: 'menu',
        id: 'documentation',
        allowed: true,
      },
    ];
  }

  getConsultantPermissions(): IUserPermission[] {
    return [
      {
        permissionType: 'menu',
        id: 'home',
        allowed: true,
      },
      {
        permissionType: 'menu',
        id: 'timesheet',
        allowed: true,
      },
      {
        permissionType: 'menu',
        id: 'documentation',
        allowed: true,
      },
    ];
  }

  mergePermissions(
    currentPermissions: IUserPermission[],
    newPermissions: IUserPermission[],
  ): IUserPermission[] {
    const resultPermissions: IUserPermission[] = currentPermissions;
    newPermissions.map((item) => {
      const existingPermission = resultPermissions.find(
        (p) => p.id === item.id && p.permissionType === item.permissionType,
      );
      if (existingPermission) {
        if (!existingPermission.allowed) {
          existingPermission.allowed = item.allowed;
        }
      } else {
        resultPermissions.push({
          ...item,
        });
      }
    });
    return resultPermissions;
  }

  getPermissionsByRole = (roles: string[]): IUserPermission[] => {
    // debugger;
    let resultPermissions: IUserPermission[] = this.getConsultantPermissions();
    roles.map((currentRole) => {
      let newPermissions: IUserPermission[] = [];
      switch (currentRole) {
        case 'admin':
        case 'managerPartner':
          newPermissions = this.getAdminPermissions();
          break;
        case 'manager':
          newPermissions = this.getManagerPermissions();
          break;
        case 'consultant':
          newPermissions = this.getConsultantPermissions();
          break;
        case 'Head of department':
          newPermissions = this.getHeadOfDepartmentPermissions();
          break;
        case 'HR':
          newPermissions = this.getHRPermissions();
          break;
      }
      resultPermissions = this.mergePermissions(resultPermissions, newPermissions);
    });

    return resultPermissions;
  };
}
