import { axiosApi } from '../configs/apiConfig';
import { AppDependencies } from './appDependencies';
import { toast } from 'react-hot-toast';
import { ICreateCatalogIssueDto } from '../dtos/catalog-issues/ICreateIssueDto';
import { ICatalogIssueDto } from '../dtos/catalog-issues/IIssueDto';

export class CatalogIssueService {
  async GetAll(searchText?: string) {
    let url = `/catalog-issues`;
    if (searchText && searchText !== '') {
      url += `/${searchText}`;
    }

    const response = await axiosApi.get(url);
    return response.data;
  }

  async GetAllByDepartment(searchText?: string) {
    let url = `/catalog-issues/by-department`;
    if (searchText && searchText !== '') {
      url += `/${searchText}`;
    }

    const response = await axiosApi.get(url);
    return response.data;
  }

  async Create(item: ICreateCatalogIssueDto): Promise<boolean> {
    try {
      const response = await AppDependencies.Axios.post('/catalog-issues', item, {});
      if (process.env.MODE === 'debug') {
        console.log(response);
      }

      if (response.status !== 200) {
        throw `requestError. status code ${response.status}`;
      }

      toast.success('Created successfully!');
      return true;
    } catch (e) {
      if (process.env.MODE === 'debug') {
        console.log(e);
      }

      toast.error('Create failure!');
    }
    return false;
  }

  async Update(item: ICreateCatalogIssueDto): Promise<boolean> {
    try {
      const response = await AppDependencies.Axios.put('/catalog-issues', item, {});
      if (process.env.MODE === 'debug') {
        console.log(response);
      }

      if (response.status === 200) {
        toast.success('Updated successfully!');
        return true;
      } else {
        throw `requestError. status code ${response.status}`;
      }
    } catch (e) {
      if (process.env.MODE === 'debug') {
        console.log(e);
      }

      toast.error('Update failure!');
    }
    return false;
  }

  async Delete(id: string, projectTypeId: string): Promise<boolean> {
    try {
      const response = await AppDependencies.Axios.delete(`/catalog-issues/${id},${projectTypeId}`);
      if (process.env.MODE === 'debug') {
        console.log(response);
      }

      if (response.status === 200) {
        toast.success('Deleted successfully!');
        return true;
      } else {
        throw `requestError. status code ${response.status}`;
      }
    } catch (e) {
      if (process.env.MODE === 'debug') {
        console.log(e);
      }

      toast.error('Delete failure!');
    }
    return false;
  }

  async GetOne(id: string, currentProjectTypeId: string): Promise<ICatalogIssueDto> {
    const url = `/catalog-issues/one/${id},${currentProjectTypeId}`;
    const response = await AppDependencies.Axios.get(url);
    return response.data;
  }
}
